<template>
  <div class="main">
    <div class="banner"></div>
    <div class="summary content">
      <div class="adv">
        <div>产品概述</div>
        <div class="sub">
          智管云是基于云计算、移动互联、物联网以及人工智能等先进技术<br />
          结合建筑工程行业规范，融合项目管理最佳实践<br />
          聚焦质量与安全的项目管理工具平台。<br />
        </div>
      </div>

      <div class="introduce_img">
        <img
          src="../../assets/img/productList/scs/details_introduce_img_1.jpg"
          alt="智能化多端管理工地，无线视频监控、安全隐患管控、工地综合巡检、值班考勤管理一应俱全"
        />
        <div class="bottom">
          智能化多端管理工地，无线视频监控、安全隐患管控、工地综合巡检、值班考勤管理一应俱全
        </div>
      </div>

      <div class="btn_lgd">
        <div class="text">智管云平台</div>
      </div>
    </div>
    <div class="adt">
      <div class="adv">
        <div>平台价值</div>
        <div class="sub">监管工地安全、人员管理、统计分析数据</div>
      </div>
      <div class="grid_group">
        <div v-for="i in advList" :key="i.id">
          <img :src="i.img" :alt="i.title" />
          <div class="title">{{ i.title }}</div>
          <div class="stp"></div>
          <div>
            {{ i.text }}
          </div>
        </div>
      </div>
    </div>
    <div class="content mid">
      <div class="adv">
        <div>平台架构</div>
        <div class="sub">技术机构与功能架构</div>
      </div>
      <div class="btn_lgd">
        <div class="text">完善可靠</div>
      </div>
      <div>
        <img
          src="../../assets/img/productList/scs/details_unified.png"
          alt="多场景适配"
        />
      </div>
    </div>
    <div class="fun">
      <div class="content">
        <div class="title_img"></div>
        <div class="title_text">产品功能</div>
        <div class="function_group">
          <div class="function_menu">
            <div class="menu" v-for="(i, index) in functionList" :key="i.id">
              <div class="menu_title">{{ i.title }}</div>
              <div class="menu_list">
                <div
                  class="menu_item"
                  v-for="(item, itemIndex) in i.list"
                  :key="item.id"
                  @click="chooseFunctionItem(index, itemIndex)"
                  :class="
                    Findex == index && FitemIndex == itemIndex ? 'active' : ''
                  "
                >
                  {{ item.name }}
                </div>
              </div>
            </div>
          </div>
          <div class="function_introduce">
            <div class="heade">
              <div class="gold_title_right">
                <div class="text">
                  {{ functionList[Findex].list[FitemIndex].name }}
                </div>
              </div>
            </div>

            <div class="img">
              <img
                :src="functionList[Findex].list[FitemIndex].img"
                :alt="functionList[Findex].list[FitemIndex].name"
              />
            </div>
            <div class="function_text">
              {{ functionList[Findex].list[FitemIndex].text }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="serive">
      <div class="adv">
        <div>产品总结</div>
        <div class="sub">为您提供完善的智能工地管理系统</div>
      </div>
      <div class="content card_group">
        <div class="serive_card" v-for="i in seriveList" :key="i.id">
          <div class="img">
            <img :src="i.img" :alt="i.title" />
          </div>
          <div class="text">
            <div class="bold">{{ i.title }}</div>
            <div>{{ i.text }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      Findex: 0,
      FitemIndex: 0,
      swiperOptions: {
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        loop: true,
      },
      advList: [
        {
          id: 1,
          img: require("../../assets/img/productList/scs/details_advantage_icon_1.png"),
          title: "隐患快速处理",
          text: "解决隐患发现后得不到及时整改和闭环，资料输出整理困难等问题",
        },
        {
          id: 2,
          img: require("../../assets/img/productList/scs/details_advantage_icon_2.png"),
          title: "精准巡检管控",
          text: "解决项目部团队整体巡检开展困难，项目部基层人员日常检查工作落实不到位等问题",
        },
        {
          id: 3,
          img: require("../../assets/img/productList/scs/details_advantage_icon_3.png"),
          title: "监控实地打通 ",
          text: "解决视频监控信息封闭，无法实时获取，没有与项目工地结合等问题",
        },
        {
          id: 4,
          img: require("../../assets/img/productList/scs/details_advantage_icon_4.png"),
          title: "工地劳务数据",
          text: "解决具体的时间投入和工地位置不了解，劳务管理上难以有可信的数据等问题",
        },
        {
          id: 5,
          img: require("../../assets/img/productList/scs/details_advantage_icon_5.png"),
          title: "内部信息共享",
          text: "解决项目内部信息共享不足等问题",
        },
        {
          id: 6,
          img: require("../../assets/img/productList/scs/details_advantage_icon_6.png"),
          title: "统计分析管理",
          text: "统计分析的工具，对项目质量实施精准管理",
        },
      ],
      functionList: [
        {
          id: 1,
          title: "商品展示及交易",
          list: [
            {
              id: 1,
              name: "视频监控",
              text: "地图列表、多地图维护；地图缩放、摄像头标注、点击预览；录像、截屏、变倍变焦、全屏、360度旋转；日期选择，播放控制；支持无线4G/5G摄像头，太阳能供电。特别适合有移动信号，且不方便接网线的工地现场；通过扫描摄像头的二维码信息，直接接入到智管云系统；打开智管云应用app，直接查看现场画面。",
              img: require("../../assets/img/productList/scs/fc/1.jpg"),
            },
            {
              id: 2,
              name: "日常巡检",
              text: "日常巡检消息通知、巡检项条目；安排人员日常巡检次数；查看人员日常巡检情况；定位每个日常巡检发现的隐患",
              img: require("../../assets/img/productList/scs/fc/2.jpg"),
            },
            {
              id: 3,
              name: "隐患管理",
              text: "查看隐患情况；导出隐患记录及罚单；隐患的相关处理流程；日常巡检与综合巡检都会把结果体现在隐患上。隐患数据是智管云最有价值的数据。隐患管理为劳务管理、工区管理、工程与安全改进提供决策支持",
              img: require("../../assets/img/productList/scs/fc/3.jpg"),
            },
            {
              id: 4,
              name: "综合巡检",
              text: "发起巡检：巡检名称、巡检时间。巡检大类：巡检大类、安全/质量。巡检人员：搜素、语音、多选。任务到人：巡检大类再选择巡检人员任务到人、人人参与、报告人。",
              img: require("../../assets/img/productList/scs/fc/4.jpg"),
            },
            {
              id: 5,
              name: "值班管理",
              text: "值班计划：日常值班计划、批量导入、清空本月。进度管理：进度内容梗概、附件导入",
              img: require("../../assets/img/productList/scs/fc/5.jpg"),
            },
            {
              id: 6,
              name: "值班管理",
              text: "值班计划：日常值班计划、批量导入、清空本月。进度管理：进度内容梗概、附件导入",
              img: require("../../assets/img/productList/scs/fc/6.jpg"),
            },
            {
              id: 7,
              name: "外出交接",
              text: "点击外出，选择外出时间，提醒任务交接。交接任务、接受任务、拒绝他人、单独发起。",
              img: require("../../assets/img/productList/scs/fc/7.jpg"),
            },
            {
              id: 8,
              name: "统计分析",
              text: "全局纵览当前项目的安全、质量、隐患等相关情况",
              img: require("../../assets/img/productList/scs/fc/8.jpg"),
            },
            {
              id: 9,
              name: "考勤打卡",
              text: "打卡明细、电子流申请、加班请假、考勤数据导出",
              img: require("../../assets/img/productList/scs/fc/9.jpg"),
            },
          ],
        },
      ],
      seriveList: [
        {
          id: 1,
          img: require("../../assets/img/productList/scs/details_case_service_1.png"),
          title: "视频监控",
          text: "远程查看工程情况。做到实时有效，提高项目经理及项目组人员对现场状况的把控",
        },
        {
          id: 2,
          img: require("../../assets/img/productList/scs/details_case_service_2.png"),
          title: "隐患管理",
          text: "保障工程安全质量，促进项目对工程安全质量的重视。做到项目高品质交付",
        },
        {
          id: 3,
          img: require("../../assets/img/productList/scs/details_case_service_3.png"),
          title: "统计分析",
          text: "项目管理有利有效有提升，帮助项目组分析项目中存在的亮点及问题，及时跟进",
        },
        {
          id: 4,
          img: require("../../assets/img/productList/scs/details_case_service_4.png"),
          title: "值班预警",
          text: "计划有序，工作有力。保证工作人员在任何情况下可以有序有质且正确的进行工作",
        },
      ],
    };
  },
  methods: {
    chooseFunctionItem(e, f) {
      this.Findex = e;
      this.FitemIndex = f;
    },
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
  },
};
</script>

<style lang="scss" src="../../assets/css/productList.scss" scoped>
</style>

<style lang="scss" scoped>
.banner {
  background-image: url("../../assets/img/productList/scs/details_function_bg.jpg");
}

.summary {
  padding: 60px 0;

  .introduce_img {
    margin-top: 96px;
    width: 1260px;
    font-size: 24px;
    .bottom {
      height: 70px;
      line-height: 70px;
      border-radius: 0px 0px 3px 3px;
      text-align: center;

      background-color: #2196f3;
      color: #ffffff;
    }
  }
  .btn_lgd {
    width: 220px;
    margin: 55px auto 0 auto;
  }
}
.adt {
  padding: 45px 0;
  width: 100%;
  height: 770px;
  background: url("../../assets/img/productList/scs/details_advantage_bg.jpg")
    no-repeat;
  background-color: #f6f9fc;
  background-position: center;
  .grid_group {
    width: 1190px;
    margin: 60px auto 0 auto;
    grid-template-rows: repeat(2, 260px);
    grid-template-columns: repeat(3, 350px);
    grid-gap: 70px;
    text-align: center;
    font-size: 16px;
    color: #616161;
    .title {
      font-size: 26px;
      font-weight: bold;
      color: #313131;
      margin: 18px 0;
    }
    .stp {
      width: 30px;
      height: 8px;
      background-color: #1b65fb;
      border-radius: 4px;
      margin: 0 auto 18px auto;
    }
  }
}
.mid {
  padding: 70px 0;
  background: url("../../assets/img/productList/b2b2cMail/details_bg.jpg");
  .btn_lgd {
    width: 110px;
    margin: 35px auto 54px auto;
  }
  .adv {
    margin-bottom: 68px;
  }
}
.fun {
  width: 100%;
  height: 700px;
  background: url("../../assets/img/productList/b2b2cMail/details_function_bg.jpg")
    no-repeat;
  background-position: center;
  .title_img {
    width: 40px;
    height: 44px;
    position: relative;
    top: -22px;
    left: calc(50% - 20px);
    background: url("../../assets/img/productList/b2b2cMail/details_function_title.png");
  }
  .title_text {
    font-size: 34px;
    color: #ffffff;
    text-align: center;
  }
  .function_group {
    display: flex;
    margin-top: 28px;
    .function_menu {
      width: 580px;
      padding: 30px 25px 0 25px;
      height: 520px;
      background-color: #f5f5f5;
      border-radius: 3px 0px 0px 3px;
      .menu_title {
        font-size: 16px;
        color: #313131;
        font-weight: bold;
      }
      .menu {
        margin-bottom: 26px;
        &:last-child {
          margin-bottom: 0;
        }
      }
      .menu_list {
        display: flex;
        flex-wrap: wrap;

        .menu_item {
          width: 250px;
          height: 40px;
          background-color: #ffffff;
          border-radius: 3px;
          font-size: 16px;
          line-height: 40px;
          color: #717171;
          position: relative;
          padding-left: 20px;
          margin-top: 35px;
          margin-right: 20px;
          &:nth-child(2n) {
            margin-right: 0;
          }
          &::before {
            width: 8px;
            height: 8px;
            background-color: #2196f3;
            border-radius: 4px;
            content: "";
            display: block;
            position: absolute;
            top: 16px;
            left: -4px;
          }
        }
        .active {
          background-color: #2196f3;
          color: #ffffff;
          &::before {
            background-color: #e8caa3;
          }
        }
      }
    }
    .function_introduce {
      width: 630px;
      height: 550px;
      background-color: #ffffff;
      border-radius: 0px 3px 3px 0px;
      .heade {
        margin-top: 20px;
        text-align: right;
        padding-right: 20px;
        .gold_title_right {
          position: relative;
          display: inline-block;
          .text {
            width: 100%;
            font-size: 30px;
            font-weight: bold;
            color: #000000;
            text-align: center;
            position: relative;
          }
          &::before {
            position: absolute;
            width: calc(100% + 45px);
            display: block;
            content: "";
            height: 17px;
            background-color: #e8caa3;
            border-radius: 8px 0px 0px 8px;
            top: 27px;
            right: -20px;
          }
        }
      }

      .img {
        width: 540px;
        margin-top: 42px;
        img {
          width: 100%;
          height: auto;
        }
      }
      .function_text {
        width: 581px;
        font-size: 16px;
        line-height: 30px;
        color: #515151;
        margin: 30px auto 0 auto;
      }
    }
  }
}
.serive {
  padding: 60px 0 80px 0;
  background-image: linear-gradient(0deg, #ffffff 0%, #f5faff 100%);
  .serive_card {
    margin-top: 46px;
    width: 225px;
    height: 320px;
    padding: 30px;
    background-color: #ffffff;
    border-radius: 10px;
    .img {
      width: 110px;
      margin: 0 auto;
    }
    .text {
      text-align: center;
      font-size: 16px;
      line-height: 30px;
      color: #717171;
      .bold {
        font-size: 24px;
        color: #000000;
        font-weight: bold;
        margin: 16px 0 28px 0;
      }
    }
  }
}
</style>